import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueFirestore from 'vue-firestore'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/database'
import 'firebase/storage'
import 'firebase/analytics'
import VueMeta from 'vue-meta'
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'
import i18n from './i18n'
import VueAnalytics from 'vue-analytics'
import VueLoading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import './assets/tailwind.css'
import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts)

import linkify from 'vue-linkify'
Vue.directive('linkified', linkify)

Vue.component('apexchart', VueApexCharts)
Vue.use(VueLoading)
Vue.use(VueFirestore)
Vue.use(Antd)
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true,
})

Vue.use(VueAnalytics, {
  id: ['2773751120', 'G-MSW9Y8VV57'],
  checkDuplicatedScript: true,
  router,
  autoTracking: {
    pageviewTemplate(route) {
      return {
        page: route.path,
        title: document.title,
        location: window.location.href,
      }
    },
    screenview: true,
  },
})

const firebaseConfig = {
  apiKey: 'AIzaSyAFBjbAXtIEHToNt_YLnlIA-VslQVsnSjQ',
  authDomain: 'livetube-portal-320307.firebaseapp.com',
  databaseURL:
    'https://livetube-portal-320307-default-rtdb.asia-southeast1.firebasedatabase.app',
  projectId: 'livetube-portal-320307',
  storageBucket: 'livetube-portal-320307.appspot.com',
  messagingSenderId: '115498381476',
  appId: '1:115498381476:web:c5e94348e1e3c33d893631',
  measurementId: 'G-MSW9Y8VV57',
}

firebase.initializeApp(firebaseConfig)

const isMobile = () => {
  if (screen.width < 768) {
    return true
  } else {
    return false
  }
}

const firestore = firebase.firestore()
const realtimeDB = firebase.database()
const storage = firebase.storage()
const auth = firebase.auth()
const analytics = firebase.analytics()

if (process.env.VUE_APP_EMU === 'true') {
  realtimeDB.useEmulator('localhost', 9000)
  auth.useEmulator('http://localhost:9099')
  firestore.useEmulator('localhost', 8080)
  storage.useEmulator('localhost', 9199)
}

Vue.prototype.$apiUrl = process.env.VUE_APP_API_URL
Vue.prototype.$db = firestore
Vue.prototype.$rdb = realtimeDB
Vue.prototype.$firebase = firebase
Vue.prototype.$auth = auth
Vue.prototype.$storage = storage
Vue.prototype.$analytics = analytics
Vue.prototype.$isMobile = isMobile
Vue.prototype.$customCssProject = [
  'ptt-debenture-062621',
  'tqm-press-conference',
  'recharging-asia-2021',
  'mchang',
  'lake-baikal',
  '1baikal',
  'mor2311',
  'finguru',
  'eucerin',
  'eucerin-test',
]
Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app')
