import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const detectUnloginGuard = (to, from, next) => {
  if (localStorage[`auth:live-tube-portal:${to.params.projectSlug}`]) {
    next()
  } else {
    next(`/${to.params.projectSlug}`)
  }
}

const detectLoginGuard = (to, from, next) => {
  if (localStorage[`auth:live-tube-portal:${to.params.projectSlug}`]) {
    next(`/${to.params.projectSlug}/sessions`)
  } else {
    next()
  }
}

const detectSessionGuard = (to, from, next) => {
  if (localStorage[`auth:live-tube-portal:${to.params.projectSlug}`]) {
    const userData = JSON.parse(
      localStorage[`auth:live-tube-portal:${to.params.projectSlug}`]
    )
    if (typeof userData.session_key !== 'undefined') {
      if (userData.session_key.includes(to.params.sessionId)) {
        next()
      } else {
        if (userData.session_key.length === 0) {
          next()
        } else {
          next(`/${to.params.projectSlug}`)
        }
      }
    } else {
      next()
    }
  } else {
    localStorage.setItem(
      'last_session',
      JSON.stringify({
        projectSlug: to.params.projectSlug,
        sessionId: to.params.sessionId,
      })
    )
    next(`/${to.params.projectSlug}`)
  }
}

const routes = [
  {
    path: '/eucerin',
    name: 'LoginEucerin',
    component: () =>
      import(
        /* webpackChunkName: "LiveSessions" */ '../views/LoginEucerin.vue'
      ),
    // beforeEnter: detectSessionGuard,
  },
  {
    path: '/eucerin-test',
    name: 'LoginEucerinTest',
    component: () =>
      import(
        /* webpackChunkName: "LiveSessions" */ '../views/LoginEucerinTest.vue'
      ),
    // beforeEnter: detectSessionGuard,
  },
  // {
  //   path: '/scb-julius-baer-market-outlook-mid-year-2021/sessions/scb-julius-baer-market-outlook-mid-year-2021-scb-julius-baer-market-outlook-mid-year-2021',
  //   name: 'LiveSessionSCB',
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "LiveSessions" */ '../views/LiveSessionSCB.vue'
  //     ),
  //   // beforeEnter: detectSessionGuard,
  // },
  // {
  //   path: '/scb-julius-baer-market-outlook-mid-year-2021',
  //   name: 'LoginSCB',
  //   component: () =>
  //     import(/* webpackChunkName: "LiveSessions" */ '../views/LoginSCB.vue'),
  //   // beforeEnter: detectSessionGuard,
  // },
  // {
  //   path: '/scb-julius-baer-market-outlook-mid-year-2021/live',
  //   name: 'LiveSessionSCB',
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "LiveSessions" */ '../views/LiveSessionNew.vue'
  //     ),
  //   // beforeEnter: detectSessionGuard,
  // },
  // {
  //   path: '/banks-and-themallgroup-join-forces-to-help-partners/sme',
  //   name: 'LiveSessionWaiting',
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "LiveSessions" */ '../views/LiveSessionPublicPlayer.vue'
  //     ),
  //   // beforeEnter: detectSessionGuard,
  // },
  // {
  //   path: '/banks-and-themallgroup-join-forces-to-help-partners/sme-waiting',
  //   name: 'LiveSessionPublicPlayer',
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "LiveSessions" */ '../views/LiveSessionWaiting.vue'
  //     ),
  //   // beforeEnter: detectSessionGuard,
  // },
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "Home" */ '../views/Home.vue'),
  },
  {
    path: '/admin',
    name: 'AdminLogin',
    component: () =>
      import(/* webpackChunkName: "AdminLogin" */ '../views/admin/Login.vue'),
  },
  {
    path: '/admin/dashboard',
    name: 'AdminDashboard',
    component: () =>
      import(
        /* webpackChunkName: "AdminDashboard" */ '../views/admin/Dashboard.vue'
      ),
  },
  {
    path: '/admin/sessions/:projectSlug',
    name: 'AdminSession',
    component: () =>
      import(
        /* webpackChunkName: "AdminSession" */ '../views/admin/Sessions.vue'
      ),
  },
  {
    path: '/admin/users/:projectSlug',
    name: 'AdminUser',
    component: () =>
      import(/* webpackChunkName: "AdminUser" */ '../views/admin/Users.vue'),
  },
  // {
  //   path: '/admin/chat/:projectSlug/:sessionId',
  //   name: 'AdminChatManagement',
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "AdminChatManagement" */ '../views/admin/ManageChat.vue'
  //     ),
  // },
  {
    path: '/admin/chat/:projectSlug/:sessionId',
    name: 'AdminChatManagement',
    component: () =>
      import(
        /* webpackChunkName: "AdminChatManagement" */ '../views/admin/ManageChatNew.vue'
      ),
  },
  {
    path: '/admin/favorite-chat/:sessionId',
    name: 'AdminFavoriteChatManagement',
    component: () =>
      import(
        /* webpackChunkName: "AdminChatManagement" */ '../views/admin/FavoriteMessageChat.vue'
      ),
  },
  {
    path: '/admin/sessions/:projectSlug/:sessionId',
    name: 'AdminSessionsPreview',
    component: () =>
      import(
        /* webpackChunkName: "AdminUsersLog" */ '../views/LiveSessionNew.vue'
      ),
  },
  {
    path: '/admin/users-log/:projectSlug/:sessionId',
    name: 'AdminUsersLog',
    component: () =>
      import(
        /* webpackChunkName: "AdminUsersLog" */ '../views/admin/UserLog.vue'
      ),
  },
  {
    path: '/admin/qa/:sessionId',
    name: 'AdminQA',
    component: () =>
      import(
        /* webpackChunkName: "AdminMessageScreen" */ '../views/admin/QAMessage.vue'
      ),
  },
  {
    path: '/admin/polls/:sessionId',
    name: 'AdminPolls',
    component: () =>
      import(
        /* webpackChunkName: "AdminMessageScreen" */ '../views/admin/Polls.vue'
      ),
  },
  {
    path: '/admin/polls/:sessionId/:pollId',
    name: 'AdminPollResultBarChart',
    component: () =>
      import(
        /* webpackChunkName: "AdminMessageScreen" */ '../views/admin/PollResultBarChart.vue'
      ),
  },
  {
    path: '/admin/message-screen/:sessionId',
    name: 'AdminMessageScreen',
    component: () =>
      import(
        /* webpackChunkName: "AdminMessageScreen" */ '../views/admin/MessageScreen.vue'
      ),
  },
  {
    path: '/:projectSlug/',
    name: 'Login',
    component: () =>
      import(/* webpackChunkName: "Login" */ '../views/Login.vue'),
    beforeEnter: detectLoginGuard,
  },
  {
    path: '/:projectSlug/register',
    name: 'Register',
    component: () =>
      import(/* webpackChunkName: "Register" */ '../views/Register.vue'),
    beforeEnter: detectLoginGuard,
  },
  {
    path: '/:projectSlug/sessions/',
    name: 'Sessions',
    component: () =>
      import(/* webpackChunkName: "Sessions" */ '../views/Sessions.vue'),
    beforeEnter: detectUnloginGuard,
  },
  {
    path: '/:projectSlug/sessions/:sessionId',
    name: 'LiveSessions',
    component: () =>
      import(
        /* webpackChunkName: "LiveSessions" */ '../views/LiveSessionNew.vue'
      ),
    beforeEnter: detectUnloginGuard,
  },
  {
    path: '/:projectSlug/sessions/:sessionId/agenda',
    name: 'Agenda',
    component: () =>
      import(/* webpackChunkName: "Agenda" */ '../views/Agenda.vue'),
    beforeEnter: detectSessionGuard,
  },
  {
    path: '/:projectSlug/sessions/:sessionId/stats',
    name: 'LiveSessionsStats',
    component: () =>
      import(/* webpackChunkName: "LiveSessions" */ '../views/Stats.vue'),
    // beforeEnter: detectSessionGuard,
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
